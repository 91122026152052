import React from 'react';

import './index.css';

import l1 from './images/1.svg';
import l2 from './images/2.svg';
import l3 from './images/3.svg';
import l4 from './images/4.svg';
import l5 from './images/5.svg';
import l6 from './images/6.svg';
import l8 from './images/8.svg';
import l9 from './images/9.svg';
import l10 from './images/10.svg';
import l11 from './images/11.svg';

class PaymentLogos extends React.Component {

    render() {

        return(
            <div className="payment_logos">
                <img src={l1} alt="" />
                <img src={l2} alt="" />
                <img src={l3} alt="" />
                <img src={l4} alt="" />
                <img src={l5} alt="" />
                <img src={l6} alt="" />
                <img src={l8} alt="" />
                <img src={l9} alt="" />
                <img src={l10} alt="" />
                <img src={l11} alt="" />
            </div>
        )
    }
}

export default PaymentLogos;