import React from 'react';
import Select from 'react-select';

import Boys from './names_boys.json';
import Girls from './names_girls.json';

import Boys2021 from './boys_2018.json';
import Girls2021 from './girls_2018.json';


class NameSelect extends React.Component {
    constructor(props) {
        super();
    }

    handleChange = (selected) => {
        const p = this.props;
        p.onChange(p.name, selected);
    };

    render() {
        const t = this;
        const p = t.props;
        const is2020 = p.year === 2020;
        const options = {
            boy: is2020 ? Boys : Boys2021,
            girl: is2020 ? Girls : Girls2021
        };

        const valid = p.validator(p.name, p.idx);
        const cls_invalid = !valid && p.validation ? 'invalid' : '';

        const required = p.required ? <span className="red">*</span> : '';

        return(
            <div onFocus={p.onInit} className={'form_chunk_wrapper fc_name ' + cls_invalid}>
                <label>Имя {required}</label>
                <Select
                    value={p.selected}
                    onChange={t.handleChange}
                    options={options[p.gender]}
                    className="dropdown"
                    classNamePrefix="dropdown"
                    placeholder=""
                    noOptionsMessage={() => "Имя не найдено! Проверьте, что пол выбран верно"}
                />
            </div>
        )
    }
}

export default NameSelect;