import React from 'react';
import CropResize from "../crop-resize";
import Uploader from "./uploader";
import ModalWrapper from "../../modal";

class PhotoUpload extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        const t = this;
        const p = t.props;

        const crop = <CropResize {...p} img={p.input} />;
        const modal = p.input ? <ModalWrapper {...p} modalOpenName={p.inputName} content={crop} /> : '';

        const img = p.output ? <img src={p.output} alt="" /> : '';
        const uploadLabel = p.output ? 'Загрузить другое фото' : 'Загрузить фото';

        const valid = p.validator(p.outputName);
        const cls_invalid = !valid && p.validation ? 'invalid' : '';

        return(
            <div className={'form_chunk_wrapper fc_photo_upload ' + cls_invalid}>
                <Uploader {...p} id="photoUpload" accept="image/*" />
                <label onClick={p.onInit} htmlFor="photoUpload">
                    <p className="fake_label">Фото ребенка <span className="red">*</span></p>
                    <div className="preview_block">
                        <div className={'pb_pic ' + p.orientation}>{img}</div>
                        <div className="pb_text">
                            {uploadLabel}

                            <div className="hint">Загрузите групповое фото при поздравлении нескольких детей</div>
                        </div>
                    </div>
                </label>
                {modal}
            </div>
        )
    }
}

export default PhotoUpload;