import React from 'react';

import './index.css';

class Messages extends React.Component {
    constructor(props) {
        super();

        this.ballsHolder = React.createRef();
        this.state = {
            scroll: 0
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll = (e) => {
        const t = this;
        const holder = t.ballsHolder.current;
        const scroll = window.scrollY;
        const offset = t.getOffsetTop(holder);

        const scrollOnHolder = scroll - offset;

        const windowH = window.innerHeight;
        const holderH = holder.clientHeight;
        const parallaxStart = -windowH;
        const parallaxStop = holderH + windowH;

        if (scrollOnHolder >= parallaxStart && scrollOnHolder <= parallaxStop) {
            t.setState({scroll: scrollOnHolder});
        }

    };

    getOffsetTop = (el) => {
        const rect = el.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return rect.top + scrollTop;
    };

    render() {
        const t = this;
        const {scroll} = t.state;
        const style = {
            ball2: {marginTop: scroll / 3 + 'px'},
            ball3: {marginTop: scroll / 6 + 'px'}
        };

        return(
            <div className="messages_wrapper">
                <div ref={this.ballsHolder} className="bg">
                    <div className="ellipse_2"> </div>
                    <div style={style.ball2} className="ball_2"> </div>
                    <div style={style.ball3} className="ball_3"> </div>
                </div>
                <div className="content_centrer">
                    <div className="message_wrapper">
                        <div className="white_right_side"> </div>
                        <div className="gradient_top_wrapper">
                            Здравствуйте, мои дорогие! В этом году приглашаю вас на самую необыкновенную Елочку. В своей секретной лаборатории я приготовил много сюрпризов и создал удивительный виртуальный мир, а Снегурочка привезла с собой цирковые представления. Жду твое письмо и фотографию, чтобы поздравить лично. Присылай скорее, чтобы я успел отвезти все подарочки к Новому году!
                        </div>
                        <div className="white_bottom_wrapper">
                            <div className="ded_userpic"><i> </i></div>
                            <div className="ded_name">
                                Дедушка Мороз
                                <span>Великий Устюг</span>
                            </div>

                            <div className="button_holder">
                                <button onClick={this.props.scrollToForm} className="white">Отправить</button>
                            </div>
                        </div>
                    </div>

                    <div className="message_wrapper_reverse">
                        <div className="white_left_side"> </div>
                        <div className="gradient_top_wrapper">
                            В этом году вы сможете увидеть, как Дедушка Мороз в своей секретной лаборатории найдет письмо и фотографию именно вашего ребенка. Дедушка Мороз несколько раз обратится к нему по имени, похвалит за хорошее поведение и напомнит, что нужно слушаться родителей.
                        </div>
                        <div className="white_bottom_wrapper">
                            <div className="ded_name">
                                Снегурочка
                                <span>Внучка Дедушки Мороза</span>
                            </div>
                            <div className="ded_userpic"><i> </i></div>
                        </div>

                        <div className="ball"> </div>
                    </div>
                </div>

                <div className="yellow_stroke_wrapper">
                    <div className="yellow_stroke">
                        <span>И это не все! Чтобы посмотреть видеопоздравление, ребенок попадет в удивительный 3D мир и получит билет на одно из двух волшебных онлайн-шоу.</span>
                    </div>

                    <div className="flake"> </div>
                </div>
            </div>
        )
    }
}

export default Messages;