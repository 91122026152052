import React from 'react';

class EmailInput extends React.Component {
    constructor(props) {
        super();
    }

    handleChange = (e) => {
        const p = this.props;
        p.onChange(e.target.name, e.target.value);
    };

    render() {
        const t = this;
        const p = t.props;

        const valid = p.validator(p.name);
        const cls_invalid = !valid && p.validation ? 'invalid' : '';

        return(
            <div className={'form_chunk_wrapper fc_email ' + cls_invalid}>
                <label>Электронная почта <span className="red">*</span></label>
                <input onFocus={p.onInit} className="text_input" type="text" value={p.selected} name={p.name} onChange={t.handleChange} />
            </div>
        )
    }
}

export default EmailInput;