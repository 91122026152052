import React from 'react';

import './index.css';

import Slides from './slides';
import {SliderArrow} from "../../icons";
import Swipe from 'react-easy-swipe';
import Player from "../player";

class Gallery extends React.Component {
    constructor(props) {
        super();

        this.state = {
            idx: 0,
            margin: 0
        }
    }

    showSlide = (idx) => {
        const t = this;
        if (idx >= 0 && idx < Slides.length) {
            let margin = t.state.margin;

            if (idx > 2 && idx < Slides.length - 3) {
                margin = -(idx - 3) * 160 - 50;
            }
            else if (idx <= 2) {
                margin = 0;
            }
            else {
                margin = -(Slides.length - 6) * 160 + 55
            }

            this.setState({idx: parseInt(idx), margin: margin});
        }
    };

    handleSlideClick = (e) => {
        const t = this;
        const idx = e.currentTarget.getAttribute('data-idx');

        t.showSlide(idx);
    };

    showPrev = () => {
        const t = this;
        const s = t.state;
        const next = s.idx - 1;

        t.showSlide(next);
    };

    showNext = () => {
        const t = this;
        const s = t.state;
        const next = s.idx + 1;

        t.showSlide(next);
    };

    preventDrag = (e) => {
        e.preventDefault();
        return false;
    };

    render() {
        const t = this;
        const {idx, margin} = t.state;

        const slidesList = Slides.map((slide, idx) => {
            const cls_current = idx === t.state.idx ? 'current' : '';
            return (
                <div key={idx} data-idx={idx} onClick={t.handleSlideClick} className={'slide ' + cls_current}>
                    <img src={slide.thumbnail} alt="" />
                </div>
            )
        });

        const feedStyle = {
            marginLeft: margin + 'px'
        };

        const arrLeft = idx > 0 ? <div onClick={t.showPrev} className="g_arrow g_arrow_prev"><SliderArrow /></div> : '';
        const arrRight = idx < Slides.length - 1 ? <div onClick={t.showNext} className="g_arrow g_arrow_next"><SliderArrow /></div> : '';

        const curSlide = Slides[idx];
        const img = curSlide.type === 'photo' ? <img className="pic" onDragStart={t.preventDrag} src={curSlide.src} alt="" /> : <Player onDragStart={t.preventDrag} url={curSlide.url} poster={curSlide.src}/>;
        const caption = curSlide.caption ? <div className="g_caption"><span>{Slides[idx].caption}</span></div> : '';

        const counter = <div className="g_counter">{idx + 1} / {Slides.length}</div>

        return(
            <div className="gallery_wrapper">
                <div className="bg">
                    <div className="ellipse_1"> </div>
                </div>

                <div className="gallery_centrer">
                    <div className="gallery">
                        <Swipe
                            onSwipeRight={t.showPrev}
                            onSwipeLeft={t.showNext}
                            allowMouseEvents>
                            <div className="g_photo">
                                {arrLeft}
                                {arrRight}

                                {img}
                                {counter}
                                {caption}
                            </div>
                        </Swipe>
                        <div className="g_slides">
                            <div style={feedStyle} className="g_slides_feed">
                                {slidesList}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Gallery;