import React from 'react';

import './index.css';
import {Exclamation, Step2, Step3, Step4, Step5, Step6} from "./steps-icons";

class RoadMap extends React.Component {
    constructor(props) {
        super();
    }

    render() {

        return(
            <div className="road_map_wrapper">
                <div className="flakes_wrapper flakes_top">
                    <div className="flakes flakes_l"> </div>
                    <div className="flakes flakes_r"> </div>
                </div>

                <div className="flakes_wrapper flakes_bottom">
                    <div className="flakes flakes_l"> </div>
                    <div className="flakes flakes_r"> </div>
                </div>
                <div className="content_centrer">
                    <h1>Как это работает?</h1>

                    <div className="steps">
                        <div className="step">
                            <div className="step_icon">
                                <span><i>1</i></span>
                                <Step2 />
                            </div>
                            <div className="step_text">
                                Заполни данные регистрации
                            </div>
                        </div>
                        <div className="step">
                            <div className="step_icon">
                                <span><i>2</i></span>
                                <Step3 />
                            </div>
                            <div className="step_text">
                                Заполни имя ребенка
                            </div>
                        </div>
                        <div className="step">
                            <div className="step_icon">
                                <span><i>3</i></span>
                                <Step4 />
                            </div>
                            <div className="step_text">
                                Загрузи фотографию ребенка
                            </div>
                        </div>
                        <div className="step">
                            <div className="step_icon">
                                <span><i>4</i></span>
                                <Step5 />
                            </div>
                            <div className="step_text">
                                Загрузи фотографию письма Дедушке Морозу
                            </div>
                        </div>

                        <div className="step">
                            <div className="step_icon">
                                <span><i>6</i></span>
                                <Step6 />
                            </div>
                            <div className="step_text">
                                Нажимай кнопку «Купить»
                            </div>
                        </div>
                    </div>

                    <div className="note">
                        <div className="icon"><Exclamation /></div>
                        <span>Если письмо не было получено в течении 24 часов проверьте, пожалуйста, папку спам или напишите нам на почту: <a href="mailto:elka@w-hub.ru">elka@w-hub.ru</a></span>
                    </div>
                </div>
            </div>
        )
    }
}

export default RoadMap;