import React from 'react';
import Header from "./components/header";

import './styles/misc.css';
import './styles/layout.css';
import Plates from "./components/plates";
import RoadMap from "./components/road-map";
import Form from "./components/form";
import Faq from "./components/faq";
import Footer from "./components/footer";


import dayjs from 'dayjs';
import Gallery from "./components/gallery";
import Features from "./components/features";
import Messages from "./components/messages";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';
import API from "./api";
import {parseQS} from "./utils";
import {AppLoadingScreen} from "./icons";

dayjs.extend(customParseFormat);

class App extends React.Component {
    constructor(props) {
        super();

        this.state = {
            products: {
                online_show_3d: {id: 12, title: "Онлайн-шоу в 3D", regular_price: "749", sale_price: "459"},
                online_show: {id: 11, title: "Онлайн-шоу", regular_price: "900", sale_price: "459"}
            },
            formAnchor: null,
            loading: false,
            promo: null,
            year: 2020
        }
    }

    componentDidMount() {

        this.checkPromo(this.getProducts);
    };

    checkPromo = (cb) => {
        const t = this;
        const search = parseQS(window.location.search.replace('?',''));

        if (search.promo) {

            t.setState({loading: true}, () => {

                const url = '/greeting/v1/promo/' + search.promo;

                API.get(url)
                    .then(function (r) {
                        t.setState({loading: false});
                        if (r.data['valid_code']) {
                            t.setState({promo: search.promo});
                        }
                        if (r.data.message) {
                            alert(r.data.message);
                        }
                        cb(r.data);
                    })
                    .catch(function (error) {
                        t.setState({loading: false});
                        console.log(error.response)
                    });
            });
        }
        else {
            cb();
        }
    };

    getProducts = (obj = {}) => {
        const t = this;
        const s = t.state;
        const url = '/greeting/v1/greeting';

        if (obj['valid_code']) {
            const products = s.products;
            products['online_show_3d']['sale_price'] = 0;

            t.setState({products: products});
        }
        else {
            API.get(url)
                .then(function (r) {
                    if (r.data.products) {
                        t.setState({products: r.data.products});
                    }
                })
                .catch(function (error) {
                    console.log(error.response)
                });
        }

    };

    scrollToForm = () => {
        if (this.state.formAnchor) {
            ym('reachGoal', 'scrollToForm');
            this.state.formAnchor.scrollIntoView({behavior: 'smooth'});
        }
    };

    setFormAnchor = (anchor) => {
        this.setState({formAnchor: anchor});
    };

    render() {
        const t = this;
        const s = t.state;
        const countdownDate = dayjs('01.01.2021, 23:59', 'DD.MM.YYYY, HH:mm').valueOf();

        const renderer = ({ days, hours, minutes, seconds }) => {
            // if (completed) {
            //     // Render a completed state
            //     return <Completionist />;
            // } else {
            //     // Render a countdown
            // }
            let oDays = days,
                oHours = hours,
                oMins = minutes,
                oSecs = seconds;

            if (days < 10 ) {
                oDays = '0' + days;
            }
            if (hours < 10 ) {
                oHours = '0' + hours;
            }
            if (minutes < 10 ) {
                oMins = '0' + minutes;
            }
            if (seconds < 10 ) {
                oSecs = '0' + seconds;
            }

            let output = <span>{oDays} : {oHours} : {oMins} : {oSecs}</span>;
            if (!days) {
                output = <span>{oHours} : {oMins} : {oSecs}</span>;

                if (!hours) {
                    output = <span>{oMins} : {oSecs}</span>;
                }
            }

            return output;
        };

        const loader = s.loading ? <AppLoadingScreen /> : '';

        return (
            <div className="app_wrapper">
                <YMInitializer accounts={[70221973]} options={{webvisor: true, clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true}} version="2"/>
                <Header promo={s.promo} products={s.products} scrollToForm={t.scrollToForm} date={countdownDate} countdown={renderer} />
                <Gallery />
                <Features />
                <Messages scrollToForm={t.scrollToForm} />
                <Plates />
                <RoadMap />
                <Form year={s.year} promo={s.promo} products={s.products} setFormAnchor={t.setFormAnchor} date={countdownDate} countdown={renderer} />
                <Faq />
                <Footer />

                {loader}
            </div>
        )
    }

}

export default App;
