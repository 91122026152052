import React from 'react';
import Player from "../../player";

class Video extends React.Component {

    render() {
        return(
            <div className="plate_video_wrapper">
                <Player url="https://vimeo.com/489945459" poster="/posters/video-preview.png" />
            </div>
        )
    }
}

export default Video;