import React from 'react';

import './index.css';

class Features extends React.Component {
    constructor(props) {
        super();
    }

    render() {

        return(
            <div className="features_wrapper">
                <div className="content_centrer">
                    <div className="number_3"> </div>
                    <div className="litera_d"> </div>
                    <div className="feature feature_1">
                        <div className="feature_inner">
                            <div className="feature_icon"> </div>
                            <div className="feature_steps"> </div>
                            <div className="feature_text">Интерактивная Елка в&nbsp;виртуальном зале</div>
                        </div>
                    </div>
                    <div className="feature feature_2">
                        <div className="feature_inner">
                            <div className="feature_icon"> </div>
                            <div className="feature_steps"> </div>
                            <div className="feature_text">100% персональное поздравление от Деда Мороза</div>
                        </div>
                    </div>
                    <div className="feature feature_3">
                        <div className="feature_inner">
                            <div className="feature_icon"> </div>
                            <div className="feature_steps"> </div>
                            <div className="feature_text">Трансляция циркового театрального онлайн шоу</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Features;