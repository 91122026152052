import React from 'react';
import Select from 'react-select';

class GenderSelect extends React.Component {
    constructor(props) {
        super();
    }

    handleChange = (selected) => {
        const p = this.props;
        p.onChange(p.name, selected);
    };

    render() {
        const t = this;
        const p = t.props;
        const options = [
            { value: 'boy', label: 'мальчик' },
            { value: 'girl', label: 'девочка' }
        ];

        return(
            <div onFocus={p.onInit} className="form_chunk_wrapper fc_gender">
                <label>Пол</label>
                <Select
                    value={p.selected}
                    onChange={t.handleChange}
                    options={options}
                    isSearchable={false}
                    className="dropdown"
                    classNamePrefix="dropdown"
                />
            </div>
        )
    }
}

export default GenderSelect;