import React from 'react';

import {PhotoUploadIcon} from "../../../icons";
import CropResize from "../crop-resize";
import ModalWrapper from "../../modal";
import Uploader from "./uploader";

class LetterUpload extends React.Component {
    constructor(props) {
        super();

        this.state = {
            selected: 0,
            letterShown: false
        }
    }

    showLetter = () => {
        this.setState({letterShown: true});
    };

    render() {
        const t = this;
        const p = t.props;
        const {letterShown} = t.state;

        const crop = <CropResize {...p} img={p.input} />;
        const modal = p.input ? <ModalWrapper {...p} modalOpenName={p.inputName} content={crop} /> : '';

        const img = p.output ? <img src={p.output} alt="" /> : '';
        const uploadLabel = p.output ? 'прикрепить другое фото' : 'прикрепить фото';
        const cls_with_preview = p.output ? 'with_preview' : '';

        const cls_hidden = letterShown ? 'hidden' : '';
        const cls_letter_visible = letterShown ? 'visible' : '';

        return(
            <div className="form_chunk_wrapper fc_letter_upload">
                <div onClick={t.showLetter} className={'show_letter ' + cls_hidden}>Добавить фото письма Деду Морозу (если есть)</div>

                <div className={'letter_switcher ' + cls_letter_visible}>
                    <p className="fake_label">При желании вы можете добавить письмо Деду Морозу. Это может быть фото письма ребенка или его рисунка. Индивидуальное видеопоздравление будет создано даже если вы не загрузите фото письма.</p>
                    <div className="default_upload_wrapper">
                        <Uploader {...p} id="letterUpload" accept="image/*" />
                        <label onClick={p.onInit} htmlFor="letterUpload" className={cls_with_preview}>
                            <div className="label_text">
                                <PhotoUploadIcon />
                                <span>{uploadLabel}</span>
                            </div>
                            <div className="img_preview">{img}</div>
                        </label>
                    </div>
                </div>


                {modal}
            </div>
        )
    }
}

export default LetterUpload;