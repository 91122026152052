import React from 'react';
import Select from 'react-select';

class AgeSelect extends React.Component {
    constructor(props) {
        super();
    }

    handleChange = (selected) => {
        const p = this.props;
        p.onChange(p.name, selected);
    };

    plural = (number, one, two, five) => {
        let n = Math.abs(number);
        n %= 100;
        if (n >= 5 && n <= 20) {
            return five;
        }
        n %= 10;
        if (n === 1) {
            return one;
        }
        if (n >= 2 && n <= 4) {
            return two;
        }
        return five;
    };

    makeAge = (from, to) => {
        const age = [];

        for(let i=from;i<to;i++) {
            age.push({value: i, label: i + ' ' + this.plural(i, 'год', 'года', 'лет')})
        }
        return age;
    };

    render() {
        const t = this;
        const p = t.props;
        const options = t.makeAge(0, 101);

        const valid = p.validator(p.name, p.idx);
        const cls_invalid = !valid && p.validation ? 'invalid' : '';

        const required = p.required ? <span className="red">*</span> : '';

        return(
            <div className={'form_chunk_wrapper fc_age ' + cls_invalid}>
                <label>Возраст {required}</label>
                <Select
                    onFocus={p.onInit}
                    value={p.selected}
                    onChange={t.handleChange}
                    options={options}
                    isSearchable={false}
                    className="dropdown"
                    classNamePrefix="dropdown"
                    placeholder="выбрать..."
                />
            </div>
        )
    }
}

export default AgeSelect;