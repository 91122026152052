import React from 'react';

import './index.css';
import bg from './images/bg@2x.png';
// import Countdown from 'react-countdown';

class Header extends React.Component {
    constructor(props) {
        super();


        this.state = {
            shift: {
                back: {x: 0, y: 0},
                bottom: {x: 0, y: 0},
                middle: {x: 0, y: 0},
                top: {x: 0, y: 0}
            },
            scrollShift: {
                back: {y: 0},
                bottom: {y: 0},
                middle: {y: 0},
                top: {y: 0}
            }
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll = (e) => {
        const t = this;
        const wH = window.innerHeight;
        const halfH = wH/2;
        const scroll = window.scrollY;

        if (scroll < wH) {
            const rY = scroll - halfH;
            const scrollShift = {
                back: {y: -rY * (7 / halfH)},
                bottom: {y: -rY * (5 / halfH)},
                middle: {y: rY * (5 / halfH)},
                top: {y: rY * (10 / halfH)}
            };

            t.setState({scrollShift: scrollShift})
        }

    };

    handleMouseMove = (e) => {
        const wW = window.innerWidth;
        const wH = window.innerHeight;
        const halfW = wW/2;
        const halfH = wH/2;
        const x = e.pageX;
        const y = e.pageY;
        const rX = x - halfW;
        const rY = y - halfH;

        const shift = {
            back: {x: -rX * (10 / halfW), y: -rY * (7 / halfH)},
            bottom: {x: -rX * (7 / halfW), y: -rY * (5 / halfH)},
            middle: {x: rX * (7 / halfW), y: rY * (5 / halfH)},
            top: {x: rX * (16 / halfW), y: rY * (10 / halfH)}
        };

        this.setState({shift: shift});
    };

    render() {
        const t = this;
        // const p = t.props;
        // const products = p.products;

        const {shift, scrollShift} = t.state;
        const style = {
            back: {marginLeft: shift.back.x + 'px', marginTop: shift.back.y + scrollShift.back.y + 'px'},
            bottom: {marginLeft: shift.bottom.x + 'px', marginTop: shift.bottom.y + scrollShift.bottom.y + 'px'},
            middle: {marginLeft: shift.middle.x + 'px', marginTop: shift.middle.y + scrollShift.middle.y + 'px'},
            top: {marginLeft: shift.top.x + 'px', marginTop: shift.top.y + scrollShift.top.y + 'px'}
        };

        // const btnText = p.promo ? 'Отправить' : 'Купить';

        return(
            <div className="header_wrapper" onMouseMove={t.handleMouseMove}>
                <div className="bg_wrapper">
                    <img className="bg" src={bg} alt="" />
                    <div className="bg_mobile"> </div>
                    <div className="pano_wrapper">
                        <div className="pano"> </div>

                        <div style={style.back} className="layer layer_back">
                            <div className="ball_7"> </div>
                            <div className="ball_8"> </div>
                        </div>
                        <div style={style.bottom} className="layer layer_bottom">
                            <div className="ball_1"> </div>
                            <div className="confetti"> </div>
                            <div className="flake"> </div>
                        </div>
                        <div style={style.middle} className="layer layer_middle">
                            <div className="letter_1"> </div>
                            <div className="ball_2"> </div>
                            <div className="ball_4"> </div>
                            <div className="ball_5"> </div>
                        </div>
                        <div style={style.top} className="layer layer_top">
                            <div className="ball_3"> </div>
                            <div className="ball_6"> </div>
                            <div className="letter_2"> </div>
                            <div className="tree"> </div>
                            <div className="gift"> </div>
                        </div>

                        <div className="ded"> </div>
                    </div>
                </div>

                <div className="header_content_wrapper">

                    <ul className="medals">
                        <li><span>онлайн шоу</span></li>
                        <li><span>3D мир</span></li>
                        <li><span>новинка 2020</span></li>
                    </ul>

                    <div className="header_content_centrer">
                        <div className="date">с 20 декабря по 9 января</div>
                        <h5>Новогодний проект маркетплейса образовательного контента <a target="_blank" rel="noopener noreferrer" href="https://w-hub.ru">w-hub.ru</a></h5>
                        <h1>Секретная лаборатория Дедушки Мороза</h1>
                    </div>

                    <div className="intro">
                        <div className="intro_text">
                            Дорогие друзья! Желаем вам счастья в 2021 году. Приём заявок на нашем сайте завершён, но мы не прощаемся и будем рады видеть вас на нашем образовательном маркетплейсе <a href="https://w-hub.ru">w-hub.ru</a><br />
                            Напоминаем, что вы можете посмотреть своё ранее созданное видеопоздравление нажав волшебную кнопку ниже.<br /><br />
                            <div className="btn_holder"><a href="https://w-hub.ru/greeting"><button className="white">Волшебная кнопка</button></a></div>
                        </div>
                        {/*<div className="countdown_price">*/}
                            {/*<p className="cd_text">скидка действует</p>*/}
                            {/*<div className="countdown"><Countdown date={this.props.date} renderer={this.props.countdown} /></div>*/}
                            {/*<div className="price">*/}
                                {/*<span className="old">{products.online_show_3d.regular_price} ₽</span>*/}
                                {/*{products.online_show_3d.sale_price} ₽*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        {/*<div className="btn_holder">*/}
                            {/*<button onClick={this.props.scrollToForm} className="blue">{btnText}</button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;