import React from 'react';

export class Exclamation extends React.Component {
    render() {
        return(
            <svg width="24" height="24" viewBox="0 0 24 24">
                <path fill="#0070e0" opacity="0.4" d="M20,10A10,10,0,1,1,10,0,10,10,0,0,1,20,10" transform="translate(2 2)"/>
                <path fill="#0070e0"
                      d="M.005,8.468a.878.878,0,1,1,.879.875A.874.874,0,0,1,.005,8.468ZM0,5.295V.875a.875.875,0,0,1,1.75,0v4.42a.875.875,0,1,1-1.75,0Z"
                      transform="translate(11.12 7.336)"/>
            </svg>
        )
    }
}

export class Step1 extends React.Component {
    render() {
        return(
            <svg className="step_1" width="48" height="48" viewBox="0 0 48 48">
                <g transform="translate(6 4)">
                    <path className="a" d="M0,.473H35.648" transform="translate(0.185 14.336)"/>
                    <path className="a" d="M.459.473H.477" transform="translate(26.426 22.147)"/>
                    <path className="a" d="M.459.473H.477" transform="translate(17.551 22.147)"/>
                    <path className="a" d="M.459.473H.477" transform="translate(8.657 22.147)"/>
                    <path className="a" d="M.459.473H.477" transform="translate(26.426 29.92)"/>
                    <path className="a" d="M.459.473H.477" transform="translate(17.551 29.92)"/>
                    <path className="a" d="M.459.473H.477" transform="translate(8.657 29.92)"/>
                    <path className="a" d="M.463,0V6.582" transform="translate(25.624)"/>
                    <path className="a" d="M.463,0V6.582" transform="translate(9.468)"/>
                    <path className="a"
                          d="M26.477,0H9.542C3.669,0,0,3.272,0,9.286v18.1c0,6.109,3.669,9.456,9.542,9.456H26.458c5.892,0,9.542-3.291,9.542-9.3V9.286C36.018,3.272,32.368,0,26.477,0Z"
                          transform="translate(0 3.158)"/>
                </g>
            </svg>
        )
    }
}

export class Step2 extends React.Component {
    render() {
        return(
            <svg className="step_2" width="48" height="48" viewBox="0 0 48 48">

                <g transform="translate(5.501 5.5)">
                    <path className="a"
                          d="M14.254,0C6.566,0,0,1.162,0,5.818s6.524,5.86,14.254,5.86c7.688,0,14.254-1.164,14.254-5.818S21.984,0,14.254,0Z"
                          transform="translate(0 24.913)"/>
                    <path className="a" d="M9.136,18.272A9.136,9.136,0,1,0,0,9.136,9.1,9.1,0,0,0,9.136,18.272Z"
                          transform="translate(5.118)"/>
                    <path className="a" d="M.5,0V8.02" transform="translate(32.407 11.838)"/>
                    <path className="a" d="M8.18.5H0" transform="translate(28.819 15.348)"/>
                </g>
            </svg>
        )
    }
}

export class Step3 extends React.Component {
    render() {
        return(
            <svg className="step_3" width="48" height="48" viewBox="0 0 48 48">
                <g transform="translate(8.5 8.5)">
                    <path className="a" d="M0,.5H12.754" transform="translate(18.899 30.796)"/>
                    <path className="a"
                          d="M17.209,1.411c1.474-1.878,3.856-1.78,5.736-.306l2.78,2.18c1.88,1.474,2.546,3.76,1.072,5.642L10.219,30.077a2.962,2.962,0,0,1-2.3,1.136L1.525,31.3.077,25.065a2.972,2.972,0,0,1,.554-2.5Z"
                          transform="translate(0 0)"/>
                    <path className="a" d="M0,0,9.588,7.516" transform="translate(14.105 5.372)"/>
                </g>
            </svg>
        )
    }
}
export class Step4 extends React.Component {
    render() {
        return(
            <svg className="step_4" height="48" viewBox="0 0 48 48">
                <rect className="a" width="48" height="48"/>
                <g transform="translate(5.5 7.5)">
                    <path className="b"
                          d="M29.776,6.72h0a1.924,1.924,0,0,1-1.74-1.1C27.462,4.4,26.732,2.848,26.3,2a3.35,3.35,0,0,0-3.1-2c-.024,0-9.368,0-9.392,0a3.352,3.352,0,0,0-3.1,2c-.43.846-1.16,2.4-1.734,3.616a1.926,1.926,0,0,1-1.74,1.1h0A7.224,7.224,0,0,0,0,13.943V25.816A7.225,7.225,0,0,0,7.226,33.04h22.55A7.225,7.225,0,0,0,37,25.816V13.943A7.224,7.224,0,0,0,29.776,6.72Z"/>
                    <path className="b" d="M0,6.364A6.357,6.357,0,1,0,6.364,0,6.38,6.38,0,0,0,0,6.364Z"
                          transform="translate(12.143 12.805)"/>
                    <path className="c"
                          d="M1.805,3.99a2.169,2.169,0,0,1-.6-.156,2.016,2.016,0,0,1-.643-.442A2.041,2.041,0,0,1,0,2a1.943,1.943,0,0,1,.165-.787A2.035,2.035,0,0,1,.636.529,2.288,2.288,0,0,1,1.245.14,2.045,2.045,0,0,1,3.409.571a1.924,1.924,0,0,1,.38.522l.046.117A1.948,1.948,0,0,1,4,2a2.033,2.033,0,0,1-.584,1.411A1.993,1.993,0,0,1,2.2,3.99L2,4Z"
                          transform="translate(26.5 10.5)"/>
                </g>
            </svg>
        )
    }
}

export class Step5 extends React.Component {
    render() {
        return(
            <svg className="step_5" width="48" height="48" viewBox="0 0 48 48">
                <g transform="translate(8.501 5.5)">
                    <path className="a"
                          d="M20.972,0H7.668A7.64,7.64,0,0,0,0,7.458v21.7a7.426,7.426,0,0,0,7.668,7.55H23.644a7.537,7.537,0,0,0,7.46-7.55v-18.6Z"
                          transform="translate(0 0.023)"/>
                    <path className="a" d="M0,0V5.818a5.149,5.149,0,0,0,5.138,5.15c2.632.006,5.326.008,5.508,0"
                          transform="translate(20.447)"/>
                    <path className="a" d="M9.8.5H0" transform="translate(10.286 19.828)"/>
                    <path className="a" d="M.5,9.8V0" transform="translate(14.687 15.428)"/>
                </g>
            </svg>
        )
    }
}

export class Step6 extends React.Component {
    render() {
        return(
            <svg className="step_6" width="48" height="48" viewBox="0 0 48 48">
                <g transform="translate(5 6)">
                    <path className="a" d="M13.481,10.765h-8.1A5.383,5.383,0,1,1,5.384,0h8.1"
                          transform="translate(24.797 12.026)"/>
                    <path className="a" d="M.923.456H.3" transform="translate(30.174 16.83)"/>
                    <path className="a"
                          d="M10.5,0H27.782a10.5,10.5,0,0,1,10.5,10.5V24.849a10.5,10.5,0,0,1-10.5,10.5H10.5A10.5,10.5,0,0,1,0,24.849V10.5A10.5,10.5,0,0,1,10.5,0Z"/>
                    <path className="a" d="M0,.456H10.8" transform="translate(9.071 8.621)"/>
                </g>
            </svg>
        )
    }
}