import React from 'react';

import Modal from 'react-modal';

import './index.css';
import {CloseCross} from "../../icons";

Modal.setAppElement('body');

class ModalWrapper extends React.Component {
    constructor(props) {
        super();

        this.state = {
            selected: 0
        }
    }

    closeModal = () => {
        const t = this;
        const p = t.props;
        p.onChange(p.modalOpenName, null);
    };

    render() {
        const t = this;
        const p = t.props;

        const cls_no_padding = p.noPadding ? 'no_padding' : '';
        const cls_middled = p.middled ? 'middled' : '';
        const cls_rounded = p.rounded ? 'rounded' : '';

        return(
            <Modal
                isOpen={true}
                className={'modal_wrapper ' + cls_middled}>
                <div className="modal_overlay" onClick={t.closeModal}> </div>
                <div className={'modal_outer ' + cls_no_padding + ' ' + cls_rounded}>
                    <div className="modal_close" onClick={t.closeModal}><CloseCross /></div>
                    <div className="modal_inner">{p.content}</div>
                </div>

            </Modal>
        )
    }
}

export default ModalWrapper;