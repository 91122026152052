import React from 'react';

import './index.css';

class Balls extends React.Component {
    constructor(props) {
        super();

        this.ballsHolder = React.createRef();

        this.state = {
            scroll: 0
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, false);
    };

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, false);
    }

    handleScroll = (e) => {
        const t = this;
        const holder = t.ballsHolder.current;
        const scroll = window.scrollY;
        const offset = t.getOffsetTop(holder);

        const scrollOnHolder = scroll - offset;

        const windowH = window.innerHeight;
        const holderH = holder.clientHeight;
        const parallaxStart = -windowH;
        const parallaxStop = holderH + windowH;

        if (scrollOnHolder >= parallaxStart && scrollOnHolder <= parallaxStop) {
            t.setState({scroll: scrollOnHolder});
        }

    };

    getOffsetTop = (el) => {
        const rect = el.getBoundingClientRect();
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return rect.top + scrollTop;
    };

    render() {
        const t = this;
        const {scroll} = t.state;

        const styles = {
            ball_l_1: {
                marginTop: scroll * -0.5 + 'px'
            },
            ball_l_2: {
                marginTop: scroll / 3 + 'px'
            },
            ball_r_1: {
                marginTop: scroll  / 3.5 + 'px'
            },
            ball_r_2: {
                marginTop: scroll / 8 + 'px'
            }
        };

        return(
            <div ref={t.ballsHolder} className="balls_wrapper">
                <div style={styles.ball_l_2} className="ball ball_l_1"> </div>
                <div style={styles.ball_r_1} className="ball ball_r_1"> </div>
            </div>
        )
    }
}

export default Balls;