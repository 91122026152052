
export const isTrueSet = (val) => {
    return val.toString() === 'true';
};

export const isFalseSet = (val) => {
    return val.toString() === 'false';
};

export const isNullSet = (val) => {
    return val.toString() === 'null';
};

export const parseQS = (str) => {
    const obj = {};
    const chunks = str.split('&');

    chunks.forEach((el) => {
        const kv = el.split('=');

        if (kv.length > 1) {
            if (isTrueSet(kv[1])) {
                obj[kv[0]] = true;
            } else if (isFalseSet(kv[1])) {
                obj[kv[0]] = false;
            } else if (isNullSet(kv[1])) {
                obj[kv[0]] = null;
            } else {
                obj[kv[0]] = kv[1];
            }
        }
    });

    return obj;
};