import React from 'react';

import './index.css';
import {VideoPreviewBtnPlay} from "../../icons";
import ModalWrapper from "../modal";
import Alisa from "./modal-content/alisa";
import IzGorod from "./modal-content/izumrudniy_gorod";
import Video from "./modal-content/video";
import Video3d from "./modal-content/3d";

class Plates extends React.Component {
    constructor(props) {
        super();

        this.state = {
            modalContent: null
        }
    }

    handleClose = () => {
        this.setState({modalContent: null})
    };

    render() {
        const t = this;
        const { modalContent } = t.state;

        const contentChunks = {
            alisa: <Alisa onChange={t.handleClose} />,
            izumrudniy_gorod: <IzGorod onChange={t.handleClose} />,
            video: <Video onChange={t.handleClose} />,
            three_d: <Video3d onChange={t.handleClose} />
        };

        const middled = modalContent === 'video' || modalContent === 'three_d';

        const modal = modalContent ? <ModalWrapper middled={middled} rounded={middled} noPadding={true} onChange={t.handleClose} modalOpenName="modalContent" content={contentChunks[modalContent]} /> : '';

        return(
            <div className="plates_wrapper">

                <div className="content_centrer">
                    <div className="plates_row">
                        <div className="plate" onClick={() => t.setState({modalContent: 'video'})}>
                            <div className="plate_pic plate_pic_video">
                                <div className="btn_play">
                                    <div className="btn_play_bg"> </div>
                                    <VideoPreviewBtnPlay />
                                </div>
                            </div>
                            <h2>Персонализированное видео поздравление от Дедушки Мороза</h2>
                            <p>Дедушка Мороз прочитает письмо именно вашего ребенка и подарит 100% индивидуальное поздравление. В своей секретной 3D лаборатории Дед Мороз посмотрит фотографию вашего малыша, несколько раз обратиться к нему по имени, похвалит его за хорошее поведение, даст индивидуальные напутствия и напомнит, что нужно слушаться родителей. Вы можете поздравить до трёх детей в одном видеопоздравление от Деда Мороза</p>
                        </div>
                        <div className="plate" onClick={() => t.setState({modalContent: 'three_d'})}>
                            <div className="plate_pic plate_pic_3d">
                                <div className="btn_play">
                                    <div className="btn_play_bg"> </div>
                                    <VideoPreviewBtnPlay />
                                </div>
                            </div>
                            <h2>3D Елочка</h2>
                            <p>Для просмотра индивидуального поздравления ваш ребенок пройдет через Новогодний 3D зал и окунется в атмосферу праздника и волшебства в виртуальном мире.</p>
                        </div>
                        <div className="plate" onClick={() => t.setState({modalContent: 'alisa'})}>
                            <div className="plate_pic plate_pic_alisa"> </div>
                            <h2>Спектакль «Алиса»</h2>
                            <p>Сказочное представление для всей семьи «Алиса» объединяет несколько жанров: музыкальный театр (все вокальные партии исполняются актерами вживую!), современный балет, цирковое и акробатическое искусство. Спектакль рассказывает увлекательную историю по мотивам классического</p>
                        </div>
                        <div className="plate" onClick={() => t.setState({modalContent: 'izumrudniy_gorod'})}>
                            <div className="plate_pic plate_pic_izumrudniy_gorod"> </div>
                            <h2>Спектакль «Изумрудный город»</h2>
                            <p>Элли и ее друзья вновь ждут гостей, чтобы вместе пережить незабываемые приключения на пути к заветному городу и к исполнению своих желаний! Захватывающий музыкально-цирковой спектакль по мотивам любимой сказки сочетает в себе зрелищные цирковые номера, яркие вокальные партии, эксклюзивные декорации и костюмы, и призван погрузить каждого в атмосферу настоящих чудес!</p>
                        </div>

                    </div>
                </div>

                {modal}
            </div>
        )
    }
}

export default Plates;