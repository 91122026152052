import React from "react";

export class StarYellow extends React.Component {

    render() {
        return(
            <svg width="16" height="15" viewBox="0 0 16 15">
                <path fill="#ffd83e"
                      d="M7.117,1.662a1,1,0,0,1,1.766,0l1.445,2.721a1,1,0,0,0,.718.517l3.048.51A1,1,0,0,1,14.64,7.1L12.5,9.263a1,1,0,0,0-.279.852l.448,3.005a1,1,0,0,1-1.428,1.046L8.439,12.8a1,1,0,0,0-.878,0l-2.8,1.366A1,1,0,0,1,3.336,13.12l.448-3.005A1,1,0,0,0,3.5,9.263L1.36,7.1A1,1,0,0,1,1.905,5.41L4.953,4.9a1,1,0,0,0,.718-.517Z"/>
            </svg>
        )
    }
}