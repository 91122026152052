import React from 'react';
import API from '../../api';

import './index.css';
import './purchase-block.css';
import './form-chunks/form-chunks.css';

import EventSelect from "./form-chunks/event-select";
import EmailInput from "./form-chunks/email-input";
import PhotoUpload from "./form-chunks/photo-upload";
import LetterUpload from "./form-chunks/letter-upload";
import Balls from "./balls";
// import DateSelect from "./form-chunks/date-select";
import ModalWrapper from "../modal";
import {StarYellow} from "./stars";
import PaymentLogos from "./payment-logos";
// import Countdown from "react-countdown";

import ym from 'react-yandex-metrika';
import Child from "./form-chunks/child";
import dayjs from "dayjs";
// import {LoaderFlake} from "../../icons";

const blackMagicName = 'DM_LAB_BLACK_MAGIC';

class Form extends React.Component {
    constructor(props) {
        super();

        this.formAnchor = React.createRef();

        const date = {
            label: dayjs().add(1, 'days').format('D MMMM YYYY, HH:mm'),
            value: dayjs().add(1, 'days').toDate().toString()
        };

        this.state = {
            payment_url: '',

            event: { value: 'izum_gorod', label: 'Изумрудный город' },
            date: date,
            email: '',
            name: null,
            age: null,
            gender: { value: 'boy', label: 'мальчик' },

            children: [
                {
                    name: null,
                    age: null,
                    gender: { value: 'boy', label: 'мальчик' }
                }
            ],

            photoOrientation: 'portrait',
            photoUploaded: null,
            photoCropped: null,
            letterOrientation: 'portrait',
            letterUploaded: null,
            letterCropped: null,

            regAccepted: true,
            tosAccepted: true,
            done: false,
            status: 'completed',

            validation: false,
            loading: false,
            formInitialized: false,

            errorCode: 'basic',
            wrongEmails: []
        };
    }

    componentDidMount() {
        const t = this;
        const p = t.props;
        p.setFormAnchor(t.formAnchor.current);
        t.checkBlackMagic();
    };

    checkBlackMagic = () => {
        const magic = localStorage.getItem(blackMagicName);

        if (magic) {
            this.checkMagicStatus(magic);
        }
    };

    checkMagicStatus = (magic) => {
        const t = this;

        API.get('https://api.w-hub.ru/wp-json/whub/v3/dedmoroz', {headers: {'Authorization': magic}})
            .then(function (r) {
                const orders = r.data.orders;
                const oLength = orders.length;
                const lastOrder = orders[oLength - 1];
                const status = lastOrder.status;

                let state = {status: status};

                if (status === 'failed') {
                    state = {status: 'fail', errorCode: 'payment_failed'}
                }

                if (status === 'completed' || status === 'failed') {
                    const goals = {
                        completed: 'paymentSuccess',
                        failed: 'paymentFailed'
                    };
                    state.done = true;
                    ym('reachGoal', goals[status]);
                    t.removeBlackMagic();
                }


                t.setState(state);
            })
            .catch(function (error) {
                console.log(error.response)
                t.removeBlackMagic();
            });
    };

    setBlackMagic = (magic) => {
        localStorage.setItem(blackMagicName, 'Bearer ' + magic);
    };

    removeBlackMagic = () => {
        localStorage.removeItem(blackMagicName);
    };

    initForm = () => {
        if (!this.state.formInitialized) {
            this.setState({formInitialized: true});
            ym('reachGoal', 'formInitialized');
        }
    };

    validator = (key) => {
        const t = this;
        const s = t.state;

        if (key === 'email' && (!t.EmailTest(s.email) || s.wrongEmails.indexOf(s.email) > -1)) {
            return false
        }
        else {
            if (!s[key]) {
                return false;
            }
        }



        return true;
    };

    validate = () => {
        const check = [
            'email',
            'date',
            'photoCropped',
            'tosAccepted',
            'regAccepted'
        ];

        for (let i=0;i<check.length;i++) {
            if (!this.validator(check[i])) {
                return false;
            }
        }

        return true;
    };

    childValidator = (key, idx) => {
        const t = this;
        const {children} = t.state;
        const child = children[idx];

        return child[key] || idx > 0;
    };

    validateChild = () => {
        const check = [
            'name',
            'age'
        ];

        for (let i=0;i<check.length;i++) {
            if (!this.childValidator(check[i], 0)) {
                return false;
            }
        }

        return true;
    };

    EmailTest = (mail) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return re.test(String(mail).toLowerCase());
    };

    enableValidation = () => {
        this.setState({validation: true}, () => {
            const invalidItems = document.querySelectorAll('.invalid');
            if (invalidItems && invalidItems[0]) {
                invalidItems[0].scrollIntoView({behavior: 'smooth'});
            }
        });
    };

    handleSubmit = (e) => {
        const productId = e.currentTarget.getAttribute('data-lot');
        const t = this;
        const s = t.state;
        const p = t.props;
        const children = s.children;
        const child = children[0];
        const isBoy = child.gender.value === 'boy';
        const vars = {
            comment: isBoy ? 1 : 2,
            achievements: isBoy ? 15 : 16,
            requests: isBoy ? 7 : 8
        };

        const valid = t.validate() && t.validateChild();

        if (!valid) {
            t.enableValidation();
        }
        else {
            let d = {
                person: {
                    name: 'x1',
                    gender: child.gender.value,
                    nameId: child.name.value
                },
                scene: 2,
                photos: [
                    {
                        base64: s.photoCropped,
                        top: 0,
                        left: 0,
                        zoom: 1,
                        orient: s.photoOrientation,
                        comment: vars.comment
                    }
                ],
                additional: {
                    age: child.age.value
                },
                behavior: {
                    achievements: vars.achievements,
                    requests: vars.requests,
                    behavior: 'good'
                },
                meta: {
                    name: child.name.label,
                    email: s.email,
                    date: s.date.value,
                    event: s.event.value,
                    nice_name: s.event.label,
                    lot: productId,
                    age: child.age.value,
                    timezone: new Date().getTimezoneOffset()/60
                }
            };

            if (s.letterCropped) {
                d.letter = {
                    base64: s.letterCropped,
                    top: 0,
                    left: 0,
                    zoom: 1,
                    orient: s.letterOrientation
                }
            }

            if (s.children.length > 1) {
                const arrIds = [];
                const arrNames = [];

                for (let i=0;i<s.children.length;i++) {
                    if (s.children[i].name && s.children[i].name.value) {
                        arrIds.push(s.children[i].name.value);
                        arrNames.push(s.children[i].name.label);
                    }
                }

                if (arrIds.length > 1) {
                    d.multiple = {
                        nameId: arrIds
                    };
                    d.meta.name = arrNames.join(', ');
                }
            }

            let url = '/greeting/v1/greeting';

            if (p.promo) {
                // url += '?promo=' + p.promo;
                d.meta.promo = p.promo;
            }

            t.setState({loading: true});

            API.post(url, d)
                .then(function (r) {
                    if (p.promo) {
                        t.setState({done: true, status: 'promo_completed', loading: false});
                        t.resetForm();
                    }
                    else if (r.data.result) {
                        const url = r.data.result;
                        const user = r.data['whub_user'];

                        if (user && user.data && user.data.token) {
                            t.setBlackMagic(user.data.token)
                        }

                        t.setState({done: true, status: 'payment', payment_url: url, loading: false});
                        t.resetForm();

                        window.googleTag();
                        ym('reachGoal', 'purchase');

                        window.open(url);
                    }
                    else {
                        t.setState({done: true, status: 'fail', loading: false});
                    }
                })
                .catch(function (error) {
                    const r = error.response;
                    console.log(r)
                    if (r.data.code) {
                        const code = r.data.code;
                        const {wrongEmails} = t.state;

                        t.setState({errorCode: code});

                        if (code === 'email_used') {
                            wrongEmails.push(s.email);
                        }

                        t.enableValidation();
                    }
                    t.setState({done: true, status: 'fail', loading: false});
                });
        }
    };

    resetForm = () => {
        this.setState({
            event: { value: 'izum_gorod', label: 'Изумрудный город' },
            date: null,
            email: '',

            photoOrientation: 'portrait',
            photoUploaded: null,
            photoCropped: null,
            letterOrientation: 'portrait',
            letterUploaded: null,
            letterCropped: null,

            regAccepted: false,
            tosAccepted: false,

            validation: false
        });
    };

    handleChange = (key, value) => {
        const t = this;
        const obj = {};
        obj[key] = value;

        const prevStateValue = JSON.parse(JSON.stringify(t.state[key]));

        t.setState(obj, () => {
            if (key === 'gender' && prevStateValue.value !== value.value) {
                t.setState({name: null});
            }
        });
    };

    handleChangeChild = (key, value, idx) => {
        const t = this;
        const {children} = t.state;
        const child = children[idx];

        const prevStateValue = JSON.parse(JSON.stringify(child[key]));

        child[key] = value;

        t.setState({children: children}, () => {
            if (key === 'gender' && prevStateValue.value !== value.value) {
                t.handleChangeChild('name', null, idx);
            }
        });
    };

    handleClose = () => {
        this.setState({done: false});
    };

    addChild = () => {
        const t = this;
        const {children} = t.state;

        children.push({
            name: null,
            age: null,
            gender: { value: 'boy', label: 'мальчик' }
        });

        t.setState({children: children})
    };


    render() {
        const t = this;
        const p = t.props;
        const {
            payment_url,
            event,
            // date,
            email,
            children,
            photoOrientation,
            photoUploaded,
            photoCropped,
            letterOrientation,
            letterUploaded,
            letterCropped,
            done,
            status,
            validation,
            // loading,
            errorCode} = t.state;
        

        // const products = p.products;

        const childrenList = children.map((child, idx) => {
            return <Child key={idx} idx={idx} year={p.year} state={t.state} child={child} onInit={t.initForm} validator={t.childValidator} changeHandler={t.handleChangeChild} />;
        });


        const cropSizes = {
            photo: {
                portrait: {width: 495, height: 660},
                landscape:  {width: 520, height: 350}
            },
            letter: {
                portrait: {width: 495, height: 660},
                landscape:  {width: 495, height: 370}
            }
        };

        const messages = {
            promo_completed: <div className="status_message sm_success">
                <h2>Поздравляем!</h2>
                <p>
                    Вы оформили билет на онлайн-шоу в Секретную лабораторию Дедушки Мороза. В ближайшее время на ваш электронный адрес придет письмо с подтверждением регистрации на онлайн-шоу. Если письмо не было получено в течение 24 часов, пожалуйста, проверьте папку спам или напишите нам на почту: <a href="mailto: elka@w-hub.ru">elka@w-hub.ru</a>
                </p>
            </div>,

            completed: <div className="status_message sm_success">
                <h2>Поздравляем! Оплата прошла успешно.</h2>
                <p>
                    Вы оформили билет на онлайн-шоу в Секретную лабораторию Дедушки Мороза. В ближайшее время на ваш электронный адрес придет письмо с подтверждением регистрации на онлайн-шоу. Если письмо не было получено в течение 24 часов, пожалуйста, проверьте папку спам или напишите нам на почту: <a href="mailto: elka@w-hub.ru">elka@w-hub.ru</a>
                </p>
            </div>,

            payment: <div className="status_message sm_fail">
                <h2>Почти готово!</h2>
                <p>
                    Сейчас вы будете перенаправлены на страницу платежного сервиса Яндекс.Деньги! Если это не произошло автоматически - нажмите кнопку "Перейти".
                </p>
                <div className="btn_holder">
                    <a rel="noopener noreferrer" target="_blank" href={payment_url}><button className="blue">Перейти</button></a>
                </div>
            </div>
        };

        const errors = {
            basic: <div className="status_message sm_fail">
                <h2>Что-то пошло не так!</h2>
                <p>
                    Ох, седая моя борода, опять что-то сломалось! Попробуй отправить анкету еще раз, если снова не получится - пиши моим эльфам на <a href="mailto: elka@w-hub.ru">elka@w-hub.ru</a>!
                </p>
            </div>,

            payment_failed: <div className="status_message sm_fail">
                <h2>Оплата не удалась!</h2>
                <p>
                    Что-то пошло не так и платеж не прошел... Но не стоит переживать! <br />
                    Вы можете перейти на страницу платежного сервиса Яндекс.Деньги нажав на кнопку:
                </p>
                <div className="btn_holder">
                    <a rel="noopener noreferrer" target="_blank" href={payment_url}><button className="blue">Перейти</button></a>
                </div>
                <p>
                    <br />
                    Либо оплатить ваш заказ перейдя по ссылке из личного кабинета!<br />
                    В течении часа на ваш электронный адрес придет письмо с подтверждением регистрации в онлайн резиденцию Дедушки Мороза. Если письмо не было получено в течении 24 часов проверьте, пожалуйста, папку спам или напишите нам на почту: <a href="mailto: elka@w-hub.ru">elka@w-hub.ru</a>
                </p>
            </div>,

            email_used: <div className="status_message sm_fail">
                <h2>Что-то пошло не так!</h2>
                <p>Этот адрес электронной почты уже был использован, либо недоступен для отправки!</p>
            </div>
        };

        const modalContent = status === 'fail' ? errors[errorCode] : messages[status];

        const modal = done ? <ModalWrapper onChange={t.handleClose} modalOpenName="done" content={modalContent} rounded={true} middled={true} /> : '';

        // const cls_loading = loading ? 'loading' : '';
        const cls_enough_kids = children.length >= 3 ? 'enough_kids' : '';

        // const btnText = p.promo ? 'Отправить' : 'Купить';

        return(
            <div ref={t.formAnchor} className="form_wrapper">
                <div className="ellipse"> </div>
                <div className="ellipse_2"> </div>
                <div className="content_centrer">
                    <div className="bg"> </div>

                    <Balls />
                    <div className="content_inner">
                        <h1>Оформление заказа</h1>
                        <h6>для получения именного видеоролика от Дедушки Мороза</h6>
                        <div className="form_section z2">

                            <EmailInput onInit={t.initForm} validation={validation} validator={t.validator} selected={email} onChange={t.handleChange} name="email" />

                            <PhotoUpload
                                onInit={t.initForm}
                                validation={validation} validator={t.validator}
                                orientationName="photoOrientation"
                                inputName="photoUploaded"
                                outputName="photoCropped"
                                orientation={photoOrientation}
                                input={photoUploaded}
                                output={photoCropped}
                                cropSize={cropSizes.photo[photoOrientation]}
                                onChange={t.handleChange}/>

                            {childrenList}
                            <div onClick={t.addChild} className={'add_child ' + cls_enough_kids}>Добавить еще одного ребенка</div>
                        </div>
                        <div className="form_section form_section_letter z1">

                            <LetterUpload
                                onInit={t.initForm}
                                orientationName="letterOrientation"
                                inputName="letterUploaded"
                                outputName="letterCropped"
                                orientation={letterOrientation}
                                input={letterUploaded}
                                output={letterCropped}
                                cropSize={cropSizes.letter[letterOrientation]}
                                onChange={t.handleChange}/>
                        </div>

                        <div className="form_section form_section_time">
                            <h6>Обычно изготовление видеопоздравления занимает от нескольких минут до 24 часов!</h6>
                            {/*<DateSelect onInit={t.initForm} validation={validation} validator={t.validator} selected={date} onChange={t.handleChange} name="date" event={event.value} />*/}

                        </div>
                        <div className="form_section z1">
                            <h3>Выберите спектакль в подарок</h3>
                            <EventSelect onInit={t.initForm} selected={event} onChange={t.handleChange} name="event" />

                        </div>

                        <div className="form_section form_section_bottom">

                            <div className="pre_bottom_buttons_wrapper">
                                <h3>Дедушка Мороз ушел на каникулы! Увидимся в следующем году!</h3>
                                {/*<div className="countdown_price">*/}
                                    {/*<p className="cd_text">скидка действует</p>*/}
                                    {/*<div className="countdown"><Countdown date={this.props.date} renderer={this.props.countdown} /></div>*/}
                                    {/*<div className="price">*/}
                                        {/*<span className="old">{products.online_show_3d.regular_price} ₽</span>*/}
                                        {/*{products.online_show_3d.sale_price} ₽*/}
                                    {/*</div>*/}
                                {/*</div>*/}

                                {/*<div className={'btn_holder ' + cls_loading}>*/}
                                    {/*<button disabled={loading} onClick={t.handleSubmit} className="blue" data-lot={products.online_show_3d.id}>*/}
                                        {/*<span className="text">{btnText}</span>*/}

                                        {/*<LoaderFlake />*/}
                                    {/*</button>*/}
                                {/*</div>*/}
                            </div>



                            <p className="after_form auto_tos">
                                Нажимая кнопку "Купить", вы подтверждаете, что согласны на обработку персональных данных, ознакомлены с <a rel="noopener noreferrer" target="_blank" href="https://w-hub.ru/docs/privacy_policy.docx">политикой конфиденциальности</a>, принимаете условия <a rel="noopener noreferrer" target="_blank" href="https://w-hub.ru/docs/offer_student.docx">договора оферты</a> и <a rel="noopener noreferrer" target="_blank" href="https://w-hub.ru/docs/user-agreement-3d.docx">пользовательского соглашения</a>
                            </p>
                            <p className="after_form after_form">
                                После оформления заказа на ваш электронный адрес придет письмо с подтверждением регистрации на онлайн-шоу. Вам необходимо установить пароль. Если письмо не было получено в течение 24 часов проверьте, пожалуйста, папку спам или напишите нам на почту: <a href="mailto: elka@w-hub.ru">elka@w-hub.ru</a>
                            </p>
                        </div>



                        <div className="purchase_block pb_highlighted">
                            <h4>Онлайн-шоу</h4>

                            <ul>
                                <li>
                                    <StarYellow />
                                    Масса приятных впечатлений от времени, которое вы проведете с ребенком отправляя виртуальное письмо Дедушке Морозу в его секретную лабораторию
                                </li>

                                <li>
                                    <StarYellow />
                                    <strong>Виртуальная Елочка.</strong> Игровое 3D-пространство, которое сможет посетить ваш ребенок для просмотра своего индивидуального видеопоздравления (доступно на платформах Window и MacOS)
                                </li>

                                <li>
                                    <StarYellow />
                                    100% индивидуальное персонализированное видеопоздравление Дедушки Мороза будет доступна для просмотра и скачивания с выбранной даты представления до 15 января 2021 года
                                </li>
                                <li>
                                    <StarYellow />
                                    Запись шоу будет доступна для просмотра с выбранной даты представления до 9 января 2021 года
                                </li>
                                <li>
                                    <StarYellow />
                                    Подарок: Трансляция волшебного циркового онлайн-спектакля на выбор
                                </li>
                                <li>
                                    <StarYellow />
                                    Индивидуальная страница поздравления
                                </li>
                            </ul>

                        </div>

                        <PaymentLogos />
                    </div>

                    <div className="envelope_back"> </div>
                    <div className="envelope_front">
                        <div className="stamp2020"> </div>
                    </div>
                </div>

                {modal}
            </div>
        )
    }
}

export default Form;