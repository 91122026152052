import React from 'react';
import GenderSelect from "./gender-select";
import NameSelect from "./name-select";
import AgeSelect from "./age-select";

class Child extends React.Component {
    constructor(props) {
        super();
    }

    handleChange = (key, value) => {
        const t = this;
        const p = t.props;

        p.changeHandler(key, value, p.idx);
    };

    render() {
        const t = this;
        const p = t.props;
        const child = p.child;
        
        const {
            validation
        } = p.state;

        return(
            <div className={'child_wrapper z_' + (3 - p.idx)}>
                <GenderSelect {...p} selected={child.gender} onChange={t.handleChange} name="gender" />
                <NameSelect {...p} validation={validation} selected={child.name} onChange={t.handleChange} name="name" required={p.idx === 0} gender={child.gender.value} />
                <AgeSelect  {...p} validation={validation} selected={child.age} onChange={t.handleChange} required={p.idx === 0} name="age" />
            </div>
        )
    }
}

export default Child;