import React from 'react';
import Select from 'react-select';

class EventSelect extends React.Component {
    constructor(props) {
        super();
    }

    handleChange = (selected) => {
        const p = this.props;
        p.onChange(p.name, selected);
    };

    render() {
        const t = this;
        const p = t.props;
        const options = [
            { value: 'izum_gorod', label: 'Изумрудный город' },
            { value: 'alisa', label: 'Алиса' }
        ];

        return(
            <div onFocus={p.onInit} className="form_chunk_wrapper fc_event_select">
                <Select
                    value={p.selected}
                    onChange={t.handleChange}
                    options={options}
                    isSearchable={false}
                    className="dropdown"
                    classNamePrefix="dropdown"
                />
                <div className="hint">запись спектакля будет доступна для просмотра до 9 января 2021 года</div>
            </div>
        )
    }
}

export default EventSelect;