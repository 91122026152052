import React from 'react';
import Player from "../../player";

class Video3d extends React.Component {

    render() {
        return(
            <div className="plate_video_wrapper">
                <Player url="https://vimeo.com/495197119" poster="/posters/8.jpg" />
            </div>
        )
    }
}

export default Video3d;