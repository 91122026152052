import React from 'react';

import './faq-item.css';
import {ArrowDown} from "../../icons";

class FaqItem extends React.Component {
    constructor(props) {
        super();

        this.answerRef = React.createRef();

        this.state = {
            opened: false
        }
    }

    toggleOpened = () => {
        const {opened} = this.state;
        this.setState({opened: !opened});
    };

    render() {
        const t = this;
        const p = t.props;

        const {opened} = t.state;
        const cls_opened = opened ? 'opened' : '';
        const ref = t.answerRef.current;
        const style = {
            height: opened ? 102 + ref.clientHeight + 'px' : '102px'
        };

        return(
            <div style={style} className={'faq_item ' + cls_opened}>
                <div onClick={t.toggleOpened} className="faq_item_q_wrapper">
                    <div className="faq_centrer">
                        <div className="faq_item_q">{p.data.q}</div>
                        <div className="faq_arrow"><ArrowDown /></div>
                    </div>
                </div>
                <div ref={t.answerRef} className="faq_item_a">
                    <div className="faq_centrer" dangerouslySetInnerHTML={{__html: p.data.a}} />
                </div>
            </div>
        )
    }
}

export default FaqItem;