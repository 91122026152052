import React from 'react';

import './index.css';

import logo from './images/logo.svg';

class Footer extends React.Component {
    constructor(props) {
        super();
    }

    render() {

        return(
            <div className="footer_wrapper">
                <div className="bg">
                    <div className="footer_chunks_wrapper">
                        <div className="ellipse_1"> </div>
                        <div className="ellipse_2"> </div>
                        <div className="ball"> </div>
                    </div>
                </div>

                <div className="content_centrer">

                    <div className="footer_text_wrapper">
                        <div className="footer_left">
                            <a rel="noopener noreferrer" target="_blank" href="https://w-hub.ru" className="logo"><img src={logo} alt="" /></a>
                            <br />
                            <p><a href="mailto:elka@w-hub.ru">elka@w-hub.ru</a></p>
                        </div>

                        <div className="footer_right">
                            <p><a rel="noopener noreferrer" target="_blank" href="https://w-hub.ru/docs/privacy_policy.docx">Политика конфиденциальности</a><br /><br />
                                <a rel="noopener noreferrer" target="_blank" href="https://w-hub.ru/docs/offer_student.docx">Договор оферты для доступа<br />к контенту на платформе W-Hub</a></p>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Footer;